<template>
    <div class="form-container" style="">
        <h2>App Settings</h2>
        <div class="input-group">
            <label for="appName">App Name</label>
            <!-- <input type="text" name="appName" id="appName" placeholder="Enter App Name" /> -->
            <input type="text" name="appName" id="appName" placeholder="Enter App Name" v-model="appName" />

        </div>

        <div class="input-group">
            <label for="segment" class="form-label">Segment</label>
            <select id="segment" name="segment" v-model="selectedSegment" @change="handleSegmentChange" aria-label="Select a Segment">
                <option value="" disabled selected>Select a Segment</option>
                <option v-for="segment in segments" :key="segment.id" :value="segment.id">
                    {{ segment.name || segment.code }}
                </option>
            </select>
        </div>



        <button class="save-btn" @click="handleSaveChanges">Save Changes</button>
    </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            appName:'',
            segments: [],
            selectedSegmentId: null,
            selectedSegment: ''
        };
    },
    mounted() {
        this.fetchSegments();
    },
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        },

        async fetchSegments() {
            const token = this.getCookie('token');
            console.log(token);

            if (!token) {
                console.error('JWT token not found in cookies');
                return;
            }

            try {
                const response = await axios.get('https://backend-pim.brndaddo.ai/api/v1/en_GB/segments', {
                    headers: {
                        JWTAuthorization: `Bearer ${token}`
                    }
                });

                this.segments = response.data.collection;
                console.log("Segments:", this.segments);
            } catch (error) {
                console.error("Error fetching segments:", error);
            }
        },

        handleSegmentChange() {
            console.log('Selected Segment:', this.selectedSegment);
            // You can perform additional logic or API calls here based on the selected segment
        },
        async handleSaveChanges(){

            // console.log(this.appName)
            if (!this.appName || !this.selectedSegment) {
                alert('Please fill in all required fields.');
                return;
            }
            const token=this.getCookie("token")
            if (!token) {
                console.error('JWT token not found in cookies');
                return;
            }
            try {
                const response = await axios.post('https://backend-pim.brndaddo.ai/api/v1/en_GB/makeApp', {
                    appName: this.appName,
                    segment: this.selectedSegment,
                    description:this.appName
                }, {
                    headers: {
                        JWTAuthorization: `Bearer ${token}`,
                    },
                });
                // console.log(response);
                //save the app id in localstorage 
                localStorage.setItem("appid",response.data.appID);

                alert("App settings saved successfully")

                // console.log('App settings saved successfully:', response.message);
            } catch (error) {
                console.error('Error saving app settings:', error);
            }
            
        }
    }
};

</script>

<style scoped>
@import 'modules/@ergonode/app/src/assets/css/appoption.css';
</style>