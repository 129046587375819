/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
export const Pages = {
    App: () => import('@App/pages/app/index').then(m => m.default || m),
};

export const Icons = {
    App: () => import('@Core/components/Icons/Menu/IconApp').then(m => m.default || m),
};



export const Tabs = {
    // AppOptions: () => import('@App/components/Tabs/AppOptionTab').then(m => m.default || m),
    // AppConfiguration: () => import('@App/components/Tabs/AppConfigTab').then(m => m.default || m),
    // AppHistory: () => import('@App/components/Tabs/AppHisotryTab').then(m => m.default || m),
    // AppScheduler: () => import('@App/components/Tabs/AppSchedulerTab').then(m => m.default || m),


 
};