<template>
    <div class="toggle-container">

        <div class="row">
            <div class="col">
                <!-- Toggle Button -->
        <label class="switch">
            <input type="checkbox" v-model="isVisible" />
            <span class="slider round"></span>
        </label>

        <!-- Conditional Rendering of Inputs Based on Toggle -->
        <div v-if="isVisible" class="input-container">
            <!-- Start Date Input -->
            <div class="input-group">
                <label for="start-date">Start Date</label>
                <input type="date" id="start-date" v-model="startDate" />
            </div>

            <!-- Start Time Input -->
            <div class="input-group">
                <label for="start-time">Start Time</label>
                <input type="time" id="start-time" v-model="startTime" />
            </div>

            <!-- Recurrence Time Inputs: Hour and Minute -->
            <div class="input-group">
                <label for="recurrence-hour">Recurrence Hour</label>
                <input type="number" id="recurrence-hour" v-model="recurrenceHour" min="0" max="23"
                    placeholder="Hours" />

                <label for="recurrence-minute">Recurrence Minute</label>
                <input type="number" id="recurrence-minute" v-model="recurrenceMinute" min="0" max="59"
                    placeholder="Minutes" />
            </div>
        </div>

            </div>
            <div class="col">


                <button class="save-btn">Sync now</button>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVisible: false, // Controls the visibility of inputs
            startDate: '', // Stores the start date value
            startTime: '', // Stores the start time value
            recurrenceHour: '', // Stores the recurrence hour value
            recurrenceMinute: '' // Stores the recurrence minute value
        };
    }
};
</script>

<style scoped>

/* Row and Column Layout */
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 100%;
}

/* Ensure columns have equal spacing */
.col-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

/* Responsive Adjustments for Row and Columns */
@media (max-width: 768px) {
    .col-3 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}
/* Toggle Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-bottom: 15px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #33393f;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* Input Container Styling */
.input-container {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.input-group {
    margin-bottom: 15px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #33393f;
}

input[type="time"],
input[type="date"],
input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: #fff;
}

input[type="time"]:focus,
input[type="date"]:focus,
input[type="number"]:focus {
    border-color: #33393f;
    outline: none;
}

/* Save Button Styling */
.save-btn {
    background-color: #33393f;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-btn:hover {
    background-color: #555b63;
}

.save-btn:focus {
    outline: none;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .input-container {
        padding: 15px;
    }

    .save-btn {
        width: 100%;
        padding: 15px;
    }
}

</style>