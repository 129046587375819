<template>
    <div class="config-container" style="">
        <h2 style="text-decoration: underline;">Configuration Tab</h2>

        <!-- First Set: API Credentials -->

        <div class="row">
            <div class="col-3">
                <h4 class="heading">Configure connection with external application</h4>
                <label for="domain">Domain</label>
                <input type="text" id="domain" placeholder="Enter your domain" name="domain" v-model="domain" />

                <label for="api-key">API Key</label>
                <input type="text" id="api_key" placeholder="Enter API Key" name="api_key" v-model="api_key" />

                <label for="api-secret-key">API Secret Key</label>
                <input type="text" id="api_secret_key" placeholder="Enter API Secret Key" name="api_secret_key"
                    v-model="api_secret_key">

                <label for="access-token">Access Token</label>
                <input type="text" id="access_token" placeholder="Enter Access Token" name="access_token"
                    v-model="access_token" />

                <button class="save-btn" @click="handleConnection">Check Connection</button>
            </div>

            <!-- Second Set: General Settings -->
            <div class="col-3">
                <h4 class="heading">Configure additional settings for external application</h4>
                <div class="input-group">
                    <label for="language" class="form-label">language</label>
                    <select id="language" name="language" v-model="selectedlanguage" @change="handlelanguageChange"
                        aria-label="Select a language">
                        <option value="" disabled selected>Select a language</option>
                        <option v-for="language in languages" :key="language.id" :value="language.id">
                            {{ language.name || language.code }}
                        </option>
                    </select>
                </div>

                <!-- <div class="input-group">
                    <label for="currency" class="form-label">Currency</label>
                    <select id="currency" name="currency" v-model="selectedcurrency" @change="handlecurrencyChange"
                        aria-label="Select a currency">
                        <option value="" disabled selected>Select a currency</option>
                        <option v-for="(name, code) in currency" :key="code" :value="code">
                            {{ name }} ({{ code }})
                        </option>
                    </select>
                </div> -->




                <div class="input-group">
                    <label for="unit" class="form-label">unit</label>
                    <select id="unit" name="unit" v-model="selectedunit" @change="handleunitChange"
                        aria-label="Select a unit">
                        <option value="" disabled selected>Select a unit</option>
                        <option v-for="uni in unit" :key="uni.id" :value="uni.id">
                            {{ uni.name || uni.code }}
                        </option>
                    </select>
                </div>
                <button class="save-btn" @click="handlesetting">Settings</button>

            </div>

            <!-- Third Set: Attribute Mapping -->
            <div class="col-3">
                <div v-if="isModalOpen" class="modal-overlay config-container" >


                    <div class="row">
                        <div class="col-6">
                        system attribute 
                        </div>
                        <div class="col-6">
                            app attribute 
                        </div>
                    </div>
                    <div class="modal-content">
                        <h2>Modal Title</h2>
                        <p>This is a modal content</p>
                        <button @click="closeModal">Close</button>
                    </div>
                </div>
                <h4 class="heading">Map data to external application objects</h4>
                <button class="save-btn" @click="openModal">Mapping Attribute</button>

            </div>
        </div>
    </div>

</template>

<script>
import axios from "axios";
// import swal from "sweetalert2"
import Swal from '../../assets/js/swal.js';


export default {
    data() {
        return {
            domain: '',
            access_token: '',
            api_secret_key: '',
            api_key: '',
            languages: '',
            selectedlanguage: '',
            currency: '',
            selectedcurrency: '',
            selectedunit: '',
            unit: '',
            currency: {},  // Initialize as an empty object,
            isModalOpen: false, // Tracks if the modal is visible





        }
    },
    mounted() {
        this.fetchlanguage(),
            this.fetchcurrency(),
            this.fetchunit()
    },
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        },
        ShowMessage(message, description, icon) {
            Swal.fire({
                icon: icon,

                title: message,
                text: description,
                allowOutsideClick: false
            });
        },
        openModal() {
        this.isModalOpen = true;
    },
    closeModal() {
        this.isModalOpen = false;
    },
        async fetchlanguage() {
            // /api/v1/{language}/languages
            const token = this.getCookie('token');
            console.log(token);

            if (!token) {
                console.error('JWT token not found in cookies');
                return;
            }

            try {
                const response = await axios.get('https://backend-pim.brndaddo.ai/api/v1/en_GB/languages', {
                    headers: {
                        JWTAuthorization: `Bearer ${token}`
                    }
                });

                this.languages = response.data.collection;
                console.log("languages:", this.languages);
            } catch (error) {
                console.error("Error fetching segments:", error);
            }

        },
        async fetchcurrency() {
            const token = this.getCookie('token');
            if (!token) {
                console.error('JWT token not found in cookies');
                return;
            }

            try {
                const response = await axios.get('https://backend-pim.brndaddo.ai/api/v1/en_GB/dictionary/currencies', {
                    headers: {
                        JWTAuthorization: `Bearer ${token}`
                    }
                });

                // Log response to confirm structure
                console.log("currency response:", response.data);

                // Assign response data correctly if it matches expected structure
                this.currency = response.data.collection || {}; // Adjust if collection is missing
            } catch (error) {
                console.error("Error fetching currency data:", error);
            }
        },

        async fetchunit() {
            // /api/v1/{language}/languages
            const token = this.getCookie('token');
            console.log(token);

            if (!token) {
                console.error('JWT token not found in cookies');
                return;
            }

            try {
                const response = await axios.get('https://backend-pim.brndaddo.ai/api/v1/en_GB/units', {
                    headers: {
                        JWTAuthorization: `Bearer ${token}`
                    }
                });

                this.unit = response.data.collection;
                console.log("unit:", this.unit);
            } catch (error) {
                console.error("Error fetching segments:", error);
            }

        },


        handlelanguageChange() {
            console.log('Selected language:', this.selectedlanguage);
        },
        handlecurrencyChange() {
            console.log('Selected currency:', this.selectedcurrency);
        },
        handleunitChange() {
            console.log('Selected currency:', this.selectedunit);

        },
        async handleConnection() {
            if (!this.domain || !this.access_token || !this.api_secret_key || !this.api_key) {
                this.ShowMessage("Oops...", "Please fill all the input fields", "success");
                // alert("go ")
                return;
            }

            const token = this.getCookie('token');
            console.log(token);
            if (!token) {
                this.ShowMessage("Error", "No JWT token found", "error");
                return;
            }

            console.log(localStorage.getItem("appid"))
            try {
                const response = await axios.post('https://backend-pim.brndaddo.ai/api/v1/en_GB/connectApp', {
                    app_id: localStorage.getItem("appid"),
                    domain: this.domain,
                    APIKey: this.api_key,
                    APISecretKey: this.api_secret_key,
                    AccessToken: this.access_token
                }, {
                    headers: {
                        JWTAuthorization: `Bearer ${token}`,
                    },
                });

                this.ShowMessage("Success", "Application confgiuration is successfull", "success");

            } catch (error) {
                this.ShowMessage("Error", "Please check again", "error");
            }
        },
        async handlesetting() {
            if (!this.selectedunit || !this.selectedlanguage) {
                this.ShowMessage("Oops...", "Please fill all the input fields", "success");


            }

            const token = this.getCookie("token")
            if (!token) {
                this.ShowMessage("Error", "No JWT token found", "error");
                return;
            }
            console.log(localStorage.getItem("appid"))
            try {
                const response = await axios.post('https://backend-pim.brndaddo.ai/api/v1/en_GB/setting', {
                    app_id: localStorage.getItem("appid"),
                    language: this.selectedlanguage,
                    weight_unit: this.selectedunit,
                }, {
                    headers: {
                        JWTAuthorization: `Bearer ${token}`,
                    },
                });

                this.ShowMessage("Success", "Successfully configured external setting", "success");

            } catch (error) {
                this.ShowMessage("Error", "Please check again", "error");
            }
        }

    }

};
</script>

<style scoped>
.config-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 1200px;
    margin: 0 auto;
}

h2 {
    font-size: 24px;
    color: #33393F;
    margin-bottom: 20px;
    text-align: center;
}

/* Row Container */
.row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    /* Increased gap between sets */
}

/* Column Styling */
.col-3 {
    flex: 0 0 30%;
    /* Each column takes 30% of the container */
    box-sizing: border-box;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    /* Border between the columns */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

label {
    display: block;
    font-weight: bold;
    color: #33393F;
    margin-bottom: 8px;
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: #fff;
}

input:focus {
    border-color: #33393F;
    outline: none;
}

/* Add extra space around the first and third sets */
.col-3:first-child {
    margin-right: 30px;
    /* Right margin between first set and middle */
}

.col-3:last-child {
    margin-left: 30px;
    /* Left margin between middle and last set */
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
    .col-3 {
        flex: 0 0 100%;
        /* Make each column 100% width on smaller screens */
        margin-bottom: 20px;
        /* Add space between sets */
    }

    .row {
        gap: 20px;
        /* Reduce gap for smaller screens */
    }
}

.save-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #33393f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #555d63;
    }
}

.heading {
    margin-bottom: 20px;
    color: #33393F
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
}

</style>