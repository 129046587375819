/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
import{ Icons,Pages }  from '@App/config/import';
import ShopifyComponent from '~/modules/@ergonode/app/src/components/ShopifyComponent';

export const ROUTE_NAME = {
    APP: 'app',
};


export default [
    {
        name: ROUTE_NAME.APP,
        path: '/app',
        component: Pages.App,
        meta: {
            access: true,
            title: 'App',
            icon: Icons.App,
            isMenu: true,
            menuPosition: 3000,
            breadcrumbs:[
                {
                title: "App",
                icon: Icons.App,
                }
            ],
        },
        
    },
    {
        name: 'shopify',
        path: '/shopify',
        component: ShopifyComponent, 
        meta: {
            title: 'Shopify',
            breadcrumbs:[
                {
                title: "App",
                icon: Icons.App,
                }
            ],
        },
    },

];
/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

// import { Icons, Pages, Tabs } from '@App/config/import';
// import ShopifyComponent from '~/modules/@ergonode/app/src/components/ShopifyComponent';

// export const ROUTE_NAME = {
//     SHOPIFY: 'shopify',
//     SHOPIFY_OPTIONS: 'shopify-options',
//     SHOPIFY_CONFIGURATION: 'shopify-configuration',
//     SHOPIFY_HISTORY: 'shopify-history',
//     SHOPIFY_SCHEDULER: 'shopify-scheduler',
// };

// export default [
//     {
//         name: ROUTE_NAME.SHOPIFY,
//         path: '/shopify',
//         component: ShopifyComponent,  // Main Shopify Component
//         redirect: { name: ROUTE_NAME.SHOPIFY_OPTIONS },  // Default redirect to Options tab
//         meta: {
//             title: 'Shopify',
//             breadcrumbs: [
//                 {
//                     title: "Shopify",
//                     icon: Icons.App,
//                 },
//             ],
//         },
//         children: [
//             {
//                 name: ROUTE_NAME.SHOPIFY_OPTIONS,
//                 path: 'options',
//                 component: Tabs.AppOptions,  // Options Tab Component
//                 meta: {
//                     title: 'Options',
//                     breadcrumbs: [
//                         {
//                             title: "Shopify",
//                             icon: Icons.App,
//                         },
//                     ],
//                 },
//             },
//             {
//                 name: ROUTE_NAME.SHOPIFY_CONFIGURATION,
//                 path: 'configuration',
//                 component: Tabs.AppConfiguration,  // Configuration Tab Component
//                 meta: {
//                     title: 'Configuration',
//                     breadcrumbs: [
//                         {
//                             title: "Shopify",
//                             icon: Icons.App,
//                         },
//                     ],
//                 },
//             },
//             {
//                 name: ROUTE_NAME.SHOPIFY_HISTORY,
//                 path: 'history',
//                 component: Tabs.AppHistory,  // History Tab Component
//                 meta: {
//                     title: 'History',
//                     breadcrumbs: [
//                         {
//                             title: "Shopify",
//                             icon: Icons.App,
//                         },
//                     ],
//                 },
//             },
//             {
//                 name: ROUTE_NAME.SHOPIFY_SCHEDULER,
//                 path: 'scheduler',
//                 component: Tabs.AppScheduler,  // Scheduler Tab Component
//                 meta: {
//                     title: 'Scheduler',
//                     breadcrumbs: [
//                         {
//                             title: "Shopify",
//                             icon: Icons.App,
//                         },
//                     ],
//                 },
//             },
//         ],
//     },
// ];
