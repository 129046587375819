var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toggle-container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("label", { staticClass: "switch" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isVisible,
                expression: "isVisible"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.isVisible)
                ? _vm._i(_vm.isVisible, null) > -1
                : _vm.isVisible
            },
            on: {
              change: function($event) {
                var $$a = _vm.isVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.isVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.isVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.isVisible = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "slider round" })
        ]),
        _vm._v(" "),
        _vm.isVisible
          ? _c("div", { staticClass: "input-container" }, [
              _c("div", { staticClass: "input-group" }, [
                _c("label", { attrs: { for: "start-date" } }, [
                  _vm._v("Start Date")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.startDate,
                      expression: "startDate"
                    }
                  ],
                  attrs: { type: "date", id: "start-date" },
                  domProps: { value: _vm.startDate },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.startDate = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c("label", { attrs: { for: "start-time" } }, [
                  _vm._v("Start Time")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.startTime,
                      expression: "startTime"
                    }
                  ],
                  attrs: { type: "time", id: "start-time" },
                  domProps: { value: _vm.startTime },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.startTime = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group" }, [
                _c("label", { attrs: { for: "recurrence-hour" } }, [
                  _vm._v("Recurrence Hour")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.recurrenceHour,
                      expression: "recurrenceHour"
                    }
                  ],
                  attrs: {
                    type: "number",
                    id: "recurrence-hour",
                    min: "0",
                    max: "23",
                    placeholder: "Hours"
                  },
                  domProps: { value: _vm.recurrenceHour },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.recurrenceHour = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "recurrence-minute" } }, [
                  _vm._v("Recurrence Minute")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.recurrenceMinute,
                      expression: "recurrenceMinute"
                    }
                  ],
                  attrs: {
                    type: "number",
                    id: "recurrence-minute",
                    min: "0",
                    max: "59",
                    placeholder: "Minutes"
                  },
                  domProps: { value: _vm.recurrenceMinute },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.recurrenceMinute = $event.target.value
                    }
                  }
                })
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("button", { staticClass: "save-btn" }, [_vm._v("Sync now")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }