var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config-container" }, [
    _c("h2", { staticStyle: { "text-decoration": "underline" } }, [
      _vm._v("Configuration Tab")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [
        _c("h4", { staticClass: "heading" }, [
          _vm._v("Configure connection with external application")
        ]),
        _vm._v(" "),
        _c("label", { attrs: { for: "domain" } }, [_vm._v("Domain")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.domain,
              expression: "domain"
            }
          ],
          attrs: {
            type: "text",
            id: "domain",
            placeholder: "Enter your domain",
            name: "domain"
          },
          domProps: { value: _vm.domain },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.domain = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "api-key" } }, [_vm._v("API Key")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.api_key,
              expression: "api_key"
            }
          ],
          attrs: {
            type: "text",
            id: "api_key",
            placeholder: "Enter API Key",
            name: "api_key"
          },
          domProps: { value: _vm.api_key },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.api_key = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "api-secret-key" } }, [
          _vm._v("API Secret Key")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.api_secret_key,
              expression: "api_secret_key"
            }
          ],
          attrs: {
            type: "text",
            id: "api_secret_key",
            placeholder: "Enter API Secret Key",
            name: "api_secret_key"
          },
          domProps: { value: _vm.api_secret_key },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.api_secret_key = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "access-token" } }, [
          _vm._v("Access Token")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.access_token,
              expression: "access_token"
            }
          ],
          attrs: {
            type: "text",
            id: "access_token",
            placeholder: "Enter Access Token",
            name: "access_token"
          },
          domProps: { value: _vm.access_token },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.access_token = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "save-btn", on: { click: _vm.handleConnection } },
          [_vm._v("Check Connection")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _c("h4", { staticClass: "heading" }, [
          _vm._v("Configure additional settings for external application")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "language" } },
            [_vm._v("language")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedlanguage,
                  expression: "selectedlanguage"
                }
              ],
              attrs: {
                id: "language",
                name: "language",
                "aria-label": "Select a language"
              },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedlanguage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.handlelanguageChange
                ]
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "", disabled: "", selected: "" } },
                [_vm._v("Select a language")]
              ),
              _vm._v(" "),
              _vm._l(_vm.languages, function(language) {
                return _c(
                  "option",
                  { key: language.id, domProps: { value: language.id } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(language.name || language.code) +
                        "\n                    "
                    )
                  ]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("label", { staticClass: "form-label", attrs: { for: "unit" } }, [
            _vm._v("unit")
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedunit,
                  expression: "selectedunit"
                }
              ],
              attrs: {
                id: "unit",
                name: "unit",
                "aria-label": "Select a unit"
              },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedunit = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.handleunitChange
                ]
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "", disabled: "", selected: "" } },
                [_vm._v("Select a unit")]
              ),
              _vm._v(" "),
              _vm._l(_vm.unit, function(uni) {
                return _c(
                  "option",
                  { key: uni.id, domProps: { value: uni.id } },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(uni.name || uni.code) +
                        "\n                    "
                    )
                  ]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "save-btn", on: { click: _vm.handlesetting } },
          [_vm._v("Settings")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-3" }, [
        _vm.isModalOpen
          ? _c("div", { staticClass: "modal-overlay config-container" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-content" }, [
                _c("h2", [_vm._v("Modal Title")]),
                _vm._v(" "),
                _c("p", [_vm._v("This is a modal content")]),
                _vm._v(" "),
                _c("button", { on: { click: _vm.closeModal } }, [
                  _vm._v("Close")
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("h4", { staticClass: "heading" }, [
          _vm._v("Map data to external application objects")
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "save-btn", on: { click: _vm.openModal } },
          [_vm._v("Mapping Attribute")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _vm._v("\n                    system attribute \n                    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _vm._v("\n                        app attribute \n                    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }