var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-container" }, [
    _c("h2", [_vm._v("App Settings")]),
    _vm._v(" "),
    _c("div", { staticClass: "input-group" }, [
      _c("label", { attrs: { for: "appName" } }, [_vm._v("App Name")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.appName,
            expression: "appName"
          }
        ],
        attrs: {
          type: "text",
          name: "appName",
          id: "appName",
          placeholder: "Enter App Name"
        },
        domProps: { value: _vm.appName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.appName = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "input-group" }, [
      _c("label", { staticClass: "form-label", attrs: { for: "segment" } }, [
        _vm._v("Segment")
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedSegment,
              expression: "selectedSegment"
            }
          ],
          attrs: {
            id: "segment",
            name: "segment",
            "aria-label": "Select a Segment"
          },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedSegment = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.handleSegmentChange
            ]
          }
        },
        [
          _c("option", { attrs: { value: "", disabled: "", selected: "" } }, [
            _vm._v("Select a Segment")
          ]),
          _vm._v(" "),
          _vm._l(_vm.segments, function(segment) {
            return _c(
              "option",
              { key: segment.id, domProps: { value: segment.id } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(segment.name || segment.code) +
                    "\n            "
                )
              ]
            )
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "save-btn", on: { click: _vm.handleSaveChanges } },
      [_vm._v("Save Changes")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }